import { vippedRowQuery } from '../../queries';

export const getVippedRowItems = async apolloClient => {
  if (!apolloClient) {
    return {};
  }

  const vippedRowQueryVars = {
    limit: 2,
    sort: 'RANDOM'
  };

  const { data, error } = await apolloClient.query({
    query: vippedRowQuery(vippedRowQueryVars),
    variables: vippedRowQueryVars
  });

  const vippedQueryData = data || null;
  const vippedQueryError = error || null;

  return {
    vippedQueryData,
    vippedQueryError
  };
};
